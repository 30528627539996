import React from "react"
import loadable from "@loadable/component"
import Loader from "../components/Loader/Loader"

/**
 * @param importFn - The dynamic import function for the component.
 * @param fallback - Optional fallback UI to display while loading.
 * @returns {React.Component} - A wrapped component with lazy-loading.
 */
const lazyLoad = (importFn, fallback = <Loader />) => {
  return loadable(importFn, {
    fallback,
  })
}

export default lazyLoad
