// Data
import FULL_PRODUCT_DATA from "../../static/data/full-product"

/**
 * @param object
 * @returns boolean - check if object is empty
 * */
export const isObjectEmpty = object => {
  return object && object.constructor === Object && Object.keys(object).length === 0
}

/**
 * @param cartItem - current cart item
 * @param giftId - the gift id set in Theme Context
 * @returns boolean - check if object is empty
 * */
export const isQuantityEnabled = (cartItem, giftId) => {
  if (!cartItem || isObjectEmpty(cartItem)) return

  let quantityEnabled = false

  // Programs && Supplements && Bundles && Promos
  if (
    FULL_PRODUCT_DATA.hasOwnProperty(cartItem.productId) &&
    (FULL_PRODUCT_DATA[cartItem.productId].type.toLowerCase() === "supplements" ||
      FULL_PRODUCT_DATA[cartItem.productId].type.toLowerCase() === "bundles")
  ) {
    if (FULL_PRODUCT_DATA[cartItem.productId].noQuantity) {
      quantityEnabled = false
    } else {
      quantityEnabled = cartItem.productId !== giftId
    }
  }

  // Apparel && Merchandise
  if (!FULL_PRODUCT_DATA.hasOwnProperty(cartItem.productId)) {
    quantityEnabled = true
  }

  return quantityEnabled
}
