const MEAL_PLANS_DATA = {
  // --- DINNER PLAN ---------------------------------------
  "gid://shopify/Product/7492241817792": {
    type: "meal plan",
    title: "My Dinner Plan",
    variants: [{ id: "gid://shopify/ProductVariant/43693693960384" }],
    img: ["mdp"],
    quote:
      "All the meals have been so delicious! I found the program very user friendly and the grocery lists are awesome!!",
    quoteAuthor: "Aimee J.",
    collection: "meal plan",
    paymentOptions: 2,
    video: 829476253,
    sellingPlans: [
      {
        sellingPlanId: 1649213632,
        sellingPlanName: "You will be charged $49 today, and an additional $49 next 2 months",
        price: 147,
        months: 3,
      },
    ],
    tabs: [],
  },
  // BFS
  "gid://shopify/Product/6667451465920": {
    type: "meal plan",
    title: "Body Fuel System",
    variants: [{ id: "gid://shopify/ProductVariant/39790736146624" }],
    img: ["bfs"],
    quote: "The Body Fuel System was everything I needed to get over my weight plateau!",
    quoteAuthor: "Jenna T.",
    collection: "meal plans",
    video: "249962643",
    paymentOptions: 1,
    tabs: ["included", "results", "faq", "start-now"],
  },
  // 30 DMP
  "gid://shopify/Product/6667428495552": {
    type: "meal plan",
    title: "30-Day Challenge Meal Plan",
    variants: [{ id: "gid://shopify/ProductVariant/39790695678144" }],
    img: ["30dmp"],
    quote: "I got the 30 day meal plan and have been loving it! I am now down a total of 14 pounds as of this morning!",
    quoteAuthor: "Richelle J.",
    collection: "meal plans",
    video: "142589434",
    paymentOptions: 1,
    tabs: ["included", "results", "faq", "start-now"],
  },
  // 7 DMP
  "gid://shopify/Product/6667192467648": {
    type: "meal plan",
    title: "7-Day Meal Plan",
    variants: [{ id: "gid://shopify/ProductVariant/39790129905856" }],
    img: ["7dmp"],
    quote: "Great coaching, great recipes, and down to earth mentoring. This rocks!",
    quoteAuthor: "Riona H.",
    collection: "meal plans",
    paymentOptions: 1,
    tabs: ["included", "results", "faq", "start-now"],
  },
}
export default MEAL_PLANS_DATA
