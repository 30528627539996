const WORKOUT_PLANS_DATA = {
  // 90DCH
  "gid://shopify/Product/6667468341440": {
    type: "workout plan",
    title: "90-Day Challenge",
    variants: [{ id: "gid://shopify/Product/6667468341440" }],
    img: ["90dch"],
    quote:
      "I’ve said it before and I’ll say it again – doing your programs has given me so much confidence. I think this is the best I have felt EVER!",
    quoteAuthor: "Angela H.",
    collection: "workout program",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 544964800,
        sellingPlanName: "You will be charged $59 today, and an additional $59 next 2 months",
        price: 177,
        months: 3,
      },
    ],
    tabs: ["included", "results", "faq", "start-now"],
  },
  // HWD
  "gid://shopify/Product/6667479253184": {
    type: "workout plan",
    title: "Home Workout Domination",
    variants: [{ id: "gid://shopify/ProductVariant/39790786740416" }],
    img: ["hwd"],
    quote:
      "I’ve been working out and eating well for a long time, but nothing had changed – I think I’ve hit the winning combination with Home Workout Domination!",
    quoteAuthor: "Jennifer H.",
    collection: "workout program",
    video: "229374313",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 555450560,
        sellingPlanName: "You will be charged $39 today, and an additional $39 next 2 months",
        price: 117,
        months: 3,
      },
    ],
    tabs: ["included", "results", "faq", "start-now"],
  },
  // HWD 2
  "gid://shopify/Product/7282410389696": {
    type: "workout plan",
    title: "Home Workout Domination 2",
    variants: [{ id: "gid://shopify/ProductVariant/42865298374848" }],
    img: ["hwd2"],
    quote:
      "I'm super stoked on how much I've achieved! I did a lot of cardio  (biking) before this and would loose weight but wasn't getting toned or  seeing the results I wanted to see. Thank you Bree and all the Betty  Rocker coaches!",
    quoteAuthor: "Jenna B.",
    collection: "workout program",
    video: "716580684",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 1207009472,
        sellingPlanName: "You will be charged $33 today, and an additional $33 next 2 months",
        price: 99,
        months: 3,
      },
    ],
    tabs: ["included", "results", "faq", "start-now"],
  },
  // 30 Day Booty Abs Challenge
  "gid://shopify/Product/6667486855360": {
    type: "workout plan",
    title: "30-Day Booty + Abs Challenge",
    variants: [{ id: "gid://shopify/ProductVariant/39790823768256" }],
    img: ["bootyabs"],
    quote:
      "I’m REALLY pleased with the changes my body is making! I feel stronger, sexier, lighter, and generally better inside and out.",
    quoteAuthor: "Laura M.",
    collection: "workout program",
    video: "142302588",
    paymentOptions: 1,
    tabs: ["included", "results", "faq", "start-now"],
  },
  // Lioness
  "gid://shopify/Product/6667482759360": {
    type: "workout plan",
    title: "Lioness",
    variants: [{ id: "gid://shopify/ProductVariant/39790810300608" }],
    img: ["lioness"],
    quote:
      "After years of trying to get my body back, the Lioness Program gave me the structure, motivation, and ease of use I’ve been searching for.",
    quoteAuthor: "Julie M.",
    collection: "workout program",
    video: "257596208",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 555483328,
        sellingPlanName: "You will be charged $49 today, and an additional $49 next 2 months.",
        price: 147,
        months: 3,
      },
    ],
    tabs: ["included", "results", "faq", "start-now"],
  },
  // Perimenofit
  "gid://shopify/Product/7928701649088": {
    type: "workout plan",
    title: "PerimenoFit",
    variants: [{ id: "gid://shopify/ProductVariant/45133771964608" }],
    img: ["perimenofit"],
    quote: "",
    quoteAuthor: "",
    collection: "workout program",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 2097545408,
        sellingPlanName: "You will be charged $57 today, and an additional $57 next 2 months.",
        price: 171,
        months: 3,
      },
    ],
    tabs: ["included", "bonuses", "faq", "start-now"],
  },
}
export default WORKOUT_PLANS_DATA
