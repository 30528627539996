/**
 * Check URL for parameters
 * @returns URL parameters (if there are any)
 * */
export const checkURLParams = () => {
  const url = typeof window !== "undefined" ? window.location.href : ""
  let parameters = {},
    hash
  let q = url.split("?")[1]
  if (q !== undefined) {
    q = q.split("&")
    for (let i = 0; i < q.length; i++) {
      hash = q[i].split("=")
      parameters[hash[0]] = hash[1]
    }
  }

  return parameters
}
