// PROMO PRODUCTS OFFER CONTENT ---------------------------------------
const OFFERS_DATA = {
  // Home Workout Domination Protein Bundles ------
  "gid://shopify/Product/7729718329536": {
    type: "bundles",
    title: "Home Workout Domination Protein Bundle",
    variants: [
      { id: "gid://shopify/ProductVariant/44562535678144" }, // 30 day All Vanilla
      { id: "gid://shopify/ProductVariant/44562535710912" }, // 30 day All Chocolate
      { id: "gid://shopify/ProductVariant/44562535743680" }, // 30 day Half and Half
      { id: "gid://shopify/ProductVariant/44562535776448" }, // 60 day All Vanilla
      { id: "gid://shopify/ProductVariant/44562535809216" }, // 60 day All Chocolate
      { id: "gid://shopify/ProductVariant/44562535841984" }, // 60 day Half and Half
    ],
    img: ["home-workout-domination-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/Product/6121460564160", // I Love Vanilla Protein
      "gid://shopify/Product/7330569388224", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: {
      offerProduct: "hwd",
      img: "offer-hwd",
      singleProduct: "Home Workout Domination",
      offerBundle: "Home Workout Domination <strong>Protein Bundle</strong>",
    },
    noQuantity: true,
    hidden: true,
  },
  // Body Fuel System Protein Bundles ------
  "gid://shopify/Product/7754890674368": {
    type: "bundles",
    title: "Body Fuel System Protein Bundle",
    variants: [
      { id: "gid://shopify/ProductVariant/44657971560640" }, // 30 day All Vanilla
      { id: "gid://shopify/ProductVariant/44657971527872" }, // 30 day All Chocolate
      { id: "gid://shopify/ProductVariant/44657971495104" }, // 30 day Half and Half
      { id: "gid://shopify/ProductVariant/44657971658944" }, // 60 day All Vanilla
      { id: "gid://shopify/ProductVariant/44657971626176" }, // 60 day All Chocolate
      { id: "gid://shopify/ProductVariant/44657971593408" }, // 60 day Half and Half
    ],
    img: ["body-fuel-system-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/Product/6121460564160", // I Love Vanilla Protein
      "gid://shopify/Product/7330569388224", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: {
      offerProduct: "bfs",
      img: "offer-bfs",
      singleProduct: "Body Fuel System",
      offerBundle: "Body Fuel System <strong>Protein Bundle</strong>",
    },
    noQuantity: true,
    hidden: true,
  },
  // Booty and Abs Challenge Protein Bundle ------
  "gid://shopify/Product/7874233270464": {
    type: "bundles",
    title: "Booty and Abs Challenge Protein Bundle",
    variants: [
      { id: "gid://shopify/ProductVariant/45002039984320" }, // 30 day All Vanilla
      { id: "gid://shopify/ProductVariant/45002039951552" }, // 30 day All Chocolate
      { id: "gid://shopify/ProductVariant/45002039918784" }, // 30 day Half and Half
      { id: "gid://shopify/ProductVariant/45002040082624" }, // 60 day All Vanilla
      { id: "gid://shopify/ProductVariant/45002040049856" }, // 60 day All Chocolate
      { id: "gid://shopify/ProductVariant/45002040017088" }, // 60 day Half and Half
    ],
    img: ["baach-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/Product/6121460564160", // I Love Vanilla Protein
      "gid://shopify/Product/7330569388224", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: {
      offerProduct: "bac",
      img: "offer-baach",
      singleProduct: "Booty and Abs Challenge",
      offerBundle: "Booty and Abs Challenge <strong>Protein Bundle</strong>",
    },
    noQuantity: true,
    hidden: true,
  },
  // 30 Day Meal Plan Protein Bundle ------
  "gid://shopify/Product/7749101551808": {
    type: "bundles",
    title: "30 Day Meal Plan Protein Bundle",
    variants: [
      { id: "gid://shopify/ProductVariant/44632243339456" }, // 30 day All Vanilla
      { id: "gid://shopify/ProductVariant/44632243306688" }, // 30 day All Chocolate
      { id: "gid://shopify/ProductVariant/44632243273920" }, // 30 day Half and Half
      { id: "gid://shopify/ProductVariant/44632958959808" }, // 60 day All Vanilla
      { id: "gid://shopify/ProductVariant/44632958927040" }, // 60 day All Chocolate
      { id: "gid://shopify/ProductVariant/44632958894272" }, // 60 day Half and Half
    ],
    img: ["30dmp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/Product/6121460564160", // I Love Vanilla Protein
      "gid://shopify/Product/7330569388224", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: {
      offerProduct: "30dmp",
      img: "offer-30dmp",
      singleProduct: "30 Day Meal Plan",
      offerBundle: "30 Day Meal Plan <strong>Protein Bundle</strong>",
    },
    noQuantity: true,
    hidden: true,
  },
  // My Dinner Plan Protein Bundle ------
  "gid://shopify/Product/7891449905344": {
    type: "bundles",
    title: "My Dinner Plan Protein Bundle",
    variants: [
      { id: "gid://shopify/ProductVariant/45029868961984" }, // 30 day All Vanilla
      { id: "gid://shopify/ProductVariant/45029868929216" }, // 30 day All Chocolate
      { id: "gid://shopify/ProductVariant/45029868896448" }, // 30 day Half and Half
      { id: "gid://shopify/ProductVariant/45029869060288" }, // 60 day All Vanilla
      { id: "gid://shopify/ProductVariant/45029869027520" }, // 60 day All Chocolate
      { id: "gid://shopify/ProductVariant/45029868994752" }, // 60 day Half and Half
    ],
    img: ["mdp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/Product/6121460564160", // I Love Vanilla Protein
      "gid://shopify/Product/7330569388224", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: {
      offerProduct: "mdp",
      img: "offer-mdp",
      singleProduct: "My Dinner Plan",
      offerBundle: "My Dinner Plan <strong>Protein Bundle</strong>",
    },
    noQuantity: true,
    hidden: true,
  },
  // 90 Day Challenge Protein Bundle ------
  "gid://shopify/Product/7751589953728": {
    type: "bundles",
    title: "90 Day Challenge Protein Bundle",
    variants: [
      { id: "gid://shopify/ProductVariant/44649379725504" }, // 30 day All Vanilla
      { id: "gid://shopify/ProductVariant/44649379692736" }, // 30 day All Chocolate
      { id: "gid://shopify/ProductVariant/44649379659968" }, // 30 day Half and Half
      { id: "gid://shopify/ProductVariant/44649379823808" }, // 60 day All Vanilla
      { id: "gid://shopify/ProductVariant/44649379791040" }, // 60 day All Chocolate
      { id: "gid://shopify/ProductVariant/44649379758272" }, // 60 day Half and Half
    ],
    img: ["90dch-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/Product/6121460564160", // I Love Vanilla Protein
      "gid://shopify/Product/7330569388224", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: {
      offerProduct: "90dch",
      img: "offer-90dch",
      singleProduct: "90 Day Challenge",
      offerBundle: "90 Day Challenge <strong>Protein Bundle</strong>",
    },
    noQuantity: true,
    hidden: true,
  },
  // Lioness Protein Bundle ------
  "gid://shopify/Product/7751590510784": {
    type: "bundles",
    title: "Lioness Protein Bundle",
    variants: [
      { id: "gid://shopify/ProductVariant/44649381200064" }, // 30 day All Vanilla
      { id: "gid://shopify/ProductVariant/44649381167296" }, // 30 day All Chocolate
      { id: "gid://shopify/ProductVariant/44649381134528" }, // 30 day Half and Half
      { id: "gid://shopify/ProductVariant/44649381298368" }, // 60 day All Vanilla
      { id: "gid://shopify/ProductVariant/44649381265600" }, // 60 day All Chocolate
      { id: "gid://shopify/ProductVariant/44649381232832" }, // 60 day Half and Half
    ],
    img: ["lioness-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/Product/6121460564160", // I Love Vanilla Protein
      "gid://shopify/Product/7330569388224", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: {
      offerProduct: "lioness",
      img: "offer-lioness",
      singleProduct: "Lioness",
      offerBundle: "Lioness <strong>Protein Bundle</strong>",
    },
    noQuantity: true,
    hidden: true,
  },
}
export default OFFERS_DATA
