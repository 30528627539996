const SUPPLEMENTS_DATA = {
  // I Love Vanilla
  "gid://shopify/Product/6121460564160": {
    type: "supplements",
    title: "I ❤ Vanilla Protein",
    variants: [{ id: "gid://shopify/ProductVariant/37751712415936" }],
    img: ["vanilla"],
    collection: "proteins",
    paymentOptions: 2,
    discountPercentage: 11.13,
    sellingPlans: [
      {
        sellingPlanId: 2055667904,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 2055700672,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 2055733440,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    bundleId: "gid://shopify/Product/7004126970048", // I Love Vanilla Bundle,
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // I Love Vanilla (challenger special)
  "gid://shopify/Product/7199137923264": {
    type: "supplements",
    title: "I ❤ Vanilla Protein",
    variants: [{ id: "gid://shopify/ProductVariant/42182264029376" }],
    img: ["vanilla"],
    hidden: true,
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // I Love Chocolate Protein
  "gid://shopify/Product/7330569388224": {
    type: "supplements",
    title: "I ❤ Chocolate Protein",
    variants: [{ id: "gid://shopify/ProductVariant/43069137518784" }],
    img: ["chocolate"],
    collection: "proteins",
    paymentOptions: 2,
    discountPercentage: 11.13,
    sellingPlans: [
      {
        sellingPlanId: 1239548096,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1239580864,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1239613632,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    bundleId: "gid://shopify/Product/7396508893376", // I love Chocolate Protein Bundle
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Berry Green Protein
  "gid://shopify/Product/2088294514758": {
    type: "supplements",
    title: "Berry Green Protein",
    variants: [{ id: "gid://shopify/ProductVariant/20808707866694" }],
    img: ["bgp"],
    collection: "proteins",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 555286720,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 555319488,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 555352256,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    bundleId: "gid://shopify/Product/7004126478528", // Berry Green Protein Bundle,
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Rock and Restore
  "gid://shopify/Product/6752033734848": {
    type: "supplements",
    title: "Rock and Restore",
    variants: [
      {
        id: "gid://shopify/ProductVariant/45833306931392", // Berry Lemonade
        hasOptions: true,
        sellingPlansImg: {
          cover: "option--berry-lemonade",
          coverDirection: "row",
          horizontal: "rr--berry-lemonade--option",
          vertical: "rr--berry-lemonade--option",
        },
      },
      {
        id: "gid://shopify/ProductVariant/45833306964160", // Fruit Punch
        sellingPlansImg: {
          cover: "option--fruit-punch",
          hasOptions: false,
          coverDirection: "row",
          horizontal: "rr--fruit-punch--option",
          vertical: "rr--fruit-punch--option",
        },
      },
    ],
    img: ["rock-and-restore"],
    collection: "proteins",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 545751232,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 545784000,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 545816768,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    bundleId: "gid://shopify/Product/7004127494336", // Rock and Restore Bundle
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Full Body Collagen
  "gid://shopify/Product/4368664854598": {
    type: "supplements",
    title: "Full Body Collagen",
    variants: [{ id: "gid://shopify/ProductVariant/31275282399302" }],
    img: ["fbc"],
    collection: "proteins",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 555188416,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 555221184,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 555253952,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    bundleId: "gid://shopify/Product/7004127854784", // Full Body Collagen Bundle
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // SereniCalm
  "gid://shopify/Product/4679620034630": {
    type: "supplements",
    title: "SereniCalm",
    variants: [{ id: "gid://shopify/ProductVariant/32084349157446" }],
    img: ["serenicalm"],
    quote:
      "I feel so much lighter, like a burden has been lifted from me. Serenicalm works extremely well for me, and I would highly recommend this product to anyone who suffers any form of anxiety or stress.",
    quoteAuthor: "Patricia B",
    collection: "other",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 554893504,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 554926272,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 554959040,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Whole Sleep
  "gid://shopify/Product/4423895580742": {
    type: "supplements",
    title: "Whole Sleep",
    variants: [{ id: "gid://shopify/ProductVariant/31431700545606" }],
    img: ["wholesleep"],
    quote:
      "Taking this product I was able to get to sleep no problem, I stayed asleep and I didn't wake up throughout the night like I usually do.",
    quoteAuthor: "Sherri J",
    collection: "other",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 554795200,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 554827968,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 554860736,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
}

export default SUPPLEMENTS_DATA
