import React, { useEffect, useState } from "react"

// Provider
import { useTheme } from "../../context/ThemeContext"

// Utilities
import lazyLoad from "../../utilities/handleLazyLoad"

// Style
import "../../styles/fontawesome/css/all.min.css"
import "../../styles/scss/app.scss"

// Components
import { Helmet } from "react-helmet"
import DiscountHelloBar from "../Discount/DiscountHelloBar"
import Header from "../Header/Header"
import Preheader from "../Header/Preheader"
const Sidebar = lazyLoad(() => import("../Sidebar/Sidebar"))
const Footer = lazyLoad(() => import("../Footer/Footer"))
const ModalCountry = lazyLoad(() => import("../Modals/ModalCountry"))
const ModalQuickBuy = lazyLoad(() => import("../Modals/ModalQuickBuy"))
const ModalSizeGuide = lazyLoad(() => import("../Modals/ModalSizeGuide"))

const Layout = ({ children }) => {
  const { isDiscountActive, shipping } = useTheme()

  /*====================================================
    HANDLE PREHEADER
    - hide it for mobile
  ====================================================*/
  const [isPreheader, setIsPreheader] = useState(true)

  useEffect(() => {
    window.addEventListener("resize", checkPreheader)
    checkPreheader()
  })

  const checkPreheader = () => setIsPreheader(window.innerWidth >= 992)

  /*====================================================
    QUICK BUY MODAL
  ====================================================*/
  const selectedShopifyProduct = useState("")
  const selectedProduct = useState("")

  const [isQuickBuyModalOpen, setIsQuickBuyModalOpen] = useState(false)

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isQuickBuyModalOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isQuickBuyModalOpen])

  /*============================
    SIZE GUIDE MODAL
  =============================*/
  const [isModalSizeGuideOpen, setIsModalSizeGuideOpen] = useState(false)

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isModalSizeGuideOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isModalSizeGuideOpen])

  return (
    <>
      {/*head*/}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />,
        <meta name="theme-color" content="#e54e89" />,<title>The Betty Rocker</title>
        <meta name="robots" content="index" />,
      </Helmet>

      {/*discount banner*/}
      {isDiscountActive && <DiscountHelloBar />}

      {/*preheader*/}
      {isPreheader && <Preheader />}

      {/*header*/}
      <Header modifierClass={`${!shipping ? "worldwide-shop" : ""} ${isDiscountActive ? "discount-active" : ""}`} />

      {/*sidebar*/}
      <Sidebar />

      {/*content*/}
      <main className={`${!shipping ? "worldwide-shop" : ""} ${isDiscountActive ? "discount-active" : ""}`}>
        {children}
      </main>

      {/*footer*/}
      <Footer pageLocation={children.props.location} />

      <ModalCountry />

      {isQuickBuyModalOpen && (
        <ModalQuickBuy
          handleCloseQuickBuyModal={() => setIsQuickBuyModalOpen(false)}
          shopifyProduct={selectedShopifyProduct}
          product={selectedProduct}
          handleOpenModalSizeGuide={() => setIsModalSizeGuideOpen(true)}
          selectedProductColor={""}
          isCart={false}
        />
      )}

      {isModalSizeGuideOpen && (
        <ModalSizeGuide
          shopifyProduct={selectedShopifyProduct}
          handleCloseModalSizeGuide={() => setIsModalSizeGuideOpen(false)}
        />
      )}
    </>
  )
}
export default Layout
