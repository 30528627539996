const BUY3_GET1_FREE_BUNDLES_DATA = {
  // I love Vanilla Protein Buy 3 Get 1 Free
  "gid://shopify/Product/7396430971072": {
    type: "bundles",
    title: "I ❤ Vanilla Protein (Buy 3 get 1 free)",
    variants: [
      {
        id: "gid://shopify/ProductVariant/43274364387520",
        sellingPlansImg: {
          horizontal: "vanilla-bundle-option-horizontal",
          vertical: "vanilla-bundle-option-vertical",
        },
      },
    ],
    img: ["vanilla-buy-3-get-1-free"],
    quote: "This is literally the BEST vanilla protein powder I have ever tasted, and I'm super picky!",
    quoteAuthor: "Heather M",
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 44.95,
    freeGiftType: "protein",
    noRegularText: true,
    optionDescription: "Stock up and Save 25% when you buy 3 and get One FREE protein.",
    saveText: "(Save 27%)",
    saveOfferText: "**BEST DEAL - Save 27%**",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
    sellingPlans: [
      {
        sellingPlanId: 1946648768,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1946681536,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1946714304,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
  },
  // I love Chocolate Protein Buy 3 Get 1 Free
  "gid://shopify/Product/7396440572096": {
    type: "bundles",
    title: "I ❤ Chocolate Protein (Buy 3 get 1 free)",
    variants: [
      {
        id: "gid://shopify/ProductVariant/43274380247232",
        sellingPlansImg: {
          horizontal: "chocolate-bundle-option-horizontal",
          vertical: "chocolate-bundle-option-vertical",
        },
      },
    ],
    img: ["chocolate-buy-3-get-1-free"],
    quote:
      "It's so good - it just tastes like  chocolate! What I usually don't like about protein powders is that  they're too grainy - but in this you can really taste the flavor and  you're not distracted by the texture - it just tastes likes a smoothie.",
    quoteAuthor: "Adei G",
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 44.95,
    freeGiftType: "protein",
    noRegularText: true,
    optionDescription: "Stock up and Save 25% when you buy 3 and get One FREE protein.",
    saveText: "(Save 27%)",
    saveOfferText: "**BEST DEAL - Save 27%**",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
    sellingPlans: [
      {
        sellingPlanId: 1955365056,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1955561664,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1955594432,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
  },
  // Rock and Restore Buy 3 Get 1 Free
  "gid://shopify/Product/7396462231744": {
    type: "bundles",
    title: "Rock and Restore Aminos (Buy 3 get 1 free)",
    variants: [
      {
        id: "gid://shopify/ProductVariant/43274408263872",
        sellingPlansImg: {
          horizontal: "rock-restore-bundle-option-horizontal",
          vertical: "rock-restore-bundle-option-vertical",
        },
      },
    ],
    img: ["rock-and-restore-buy-3-get-1-free"],
    quote:
      "I notice a difference in my recovery time for my workouts, and my muscle definition seems to be popping more.",
    quoteAuthor: "Amanda M.",
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 59.95,
    freeGiftType: "protein",
    noRegularText: true,
    optionDescription: "Stock up and Save 25% when you buy 3 and get One FREE Rock and Restore.",
    saveText: "(Save 25%)",
    saveOfferText: "**BEST DEAL - Save 25%**",
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
    sellingPlans: [
      {
        sellingPlanId: 1955397824,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1955627200,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1955659968,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
  },

  // Full Body Collagen Buy 3 Get 1 Free
  "gid://shopify/Product/7396457218240": {
    type: "bundles",
    title: "Full Body Collagen (Buy 3 get 1 free)",
    variants: [
      {
        id: "gid://shopify/ProductVariant/43274401710272",
        sellingPlansImg: {
          horizontal: "full-body-collagen-bundle-option-horizontal",
          vertical: "full-body-collagen-bundle-option-vertical",
        },
      },
    ],
    img: ["fbc-buy-3-get-1-free"],
    quote:
      "I have used other collagens in the past and this one is by far the cleanest and the smoothest - it’s completely tasteless and it’s very versatile!",
    quoteAuthor: "Stephenie M",
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 59.95,
    freeGiftType: "protein",
    noRegularText: true,
    optionDescription: "Stock up and Save 25% when you buy 3 and get One FREE Full Body Collagen.",
    saveText: "(Save 25%)",
    saveOfferText: "**BEST DEAL - Save 25%**",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
    sellingPlans: [
      {
        sellingPlanId: 1955332288,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1955496128,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1955528896,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
  },
  // Berry Green Protein Buy 3 Get 1 Free
  "gid://shopify/Product/7396449091776": {
    type: "bundles",
    title: "Berry Green Protein (Buy 3 get 1 free)",
    variants: [
      {
        id: "gid://shopify/ProductVariant/43274391945408",
        sellingPlansImg: {
          horizontal: "berry-green-bundle-option-horizontal",
          vertical: "berry-green-bundle-option-vertical",
        },
      },
    ],
    img: ["bgp-buy-3-get-1-free"],
    quote:
      "I love that the Berry Green Protein is delicious just with water when I’m in a hurry or I can put it in my smoothies - and I’m going to bake with it next!",
    quoteAuthor: "Holly L",
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 54.95,
    freeGiftType: "protein",
    noRegularText: true,
    optionDescription: "Stock up and Save 25% when you buy 3 and get One FREE protein.",
    saveText: "(Save 25%)",
    saveOfferText: "**BEST DEAL - Save 25%**",
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
    sellingPlans: [
      {
        sellingPlanId: 1955299520,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1955430592,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1955463360,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
  },
}

export default BUY3_GET1_FREE_BUNDLES_DATA
