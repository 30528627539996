import React from "react"
import { ErrorBoundary } from "@sentry/gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

/**
 * Show error boundary
 * @param error - the error returned (unused)
 * @param resetError - reset the error when redirected to Homepage
 * */
const FallBack = ({ error, resetError }) => (
  <>
    <Helmet>
      <title>Something went wrong | The Betty Rocker</title>
    </Helmet>

    <div className="container error-container d-flex flex-column justify-content-center align-items-center py--15">
      <h1 className="h2 txt--center mb--30">Something went wrong!</h1>
      <Link to="/" onClick={resetError} className="btn btn--lg">
        Go To Homepage
      </Link>
    </div>
  </>
)
const ErrorBoundaryContainer = ({ children }) => <ErrorBoundary fallback={FallBack}>{children}</ErrorBoundary>

export default ErrorBoundaryContainer
