import React from "react"
import { useTheme } from "../../context/ThemeContext"
import lazyLoad from "../../utilities/handleLazyLoad"

// Components
const Countdown = lazyLoad(() => import("../Countdown/Countdown"))

const DiscountHelloBar = () => {
  const { shipping, discountThroughText, endDateTimeBasedDiscount } = useTheme()

  return (
    <>
      {shipping && (
        <div className="discount-preheader">
          <div className="container">
            <div className="d-flex flex-wrap justify-content-center align-items-center">
              <p className="discount-preheader__txt">
                <img src="/images/discounts/icon-star.svg" width={22} height={22} alt="Icon star." className="mr--5" />{" "}
                <strong className="fw--bold mr--5">20% OFF ALL SUPPLEMENTS</strong>{" "}
                <span className="d-inline-block">
                  through {discountThroughText}!{" "}
                  <img
                    src="/images/discounts/icon-star.svg"
                    width={22}
                    height={22}
                    alt="Icon star."
                    className="ml--5"
                  />{" "}
                </span>
              </p>

              {endDateTimeBasedDiscount.length > 0 && (
                <Countdown endTime={endDateTimeBasedDiscount} modifierClass="ml--15" />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default DiscountHelloBar
