import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

const Loader = ({ isLoader }) => {
  /*====================================================
   REMOVE OVERFLOW IF NO LOADER
  ====================================================*/
  useEffect(() => {
    if (!isLoader) {
      document.body.classList.remove("page-loader--overflow")
    }
  }, [isLoader])

  return (
    <>
      <Helmet
        bodyAttributes={{
          className: isLoader ? "page-loader--overflow" : "",
        }}
      ></Helmet>
      {isLoader && <span className="page-loader" />}
    </>
  )
}
export default Loader
