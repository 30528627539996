import { useStaticQuery, graphql } from "gatsby"

export const UseShopifyDataQuery = () => {
  let shopifyList = []

  const shopify = useStaticQuery(graphql`
    {
      allShopifyProduct(sort: { title: ASC }) {
        edges {
          node {
            title
            totalInventory
            tracksInventory
            tags
            options {
              name
              values
            }
            media {
              ... on ShopifyMediaImage {
                image {
                  originalSrc
                  altText
                }
              }
            }
            shopifyId
            legacyResourceId
            storefrontId
            descriptionHtml
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            createdAt
            handle
            variants {
              price
              inventoryQuantity
              compareAtPrice
              availableForSale
              shopifyId
              legacyResourceId
              storefrontId
              title
              selectedOptions {
                name
                value
              }
              media {
                ... on ShopifyMediaImage {
                  image {
                    originalSrc
                  }
                }
              }
              sku
            }
          }
        }
      }
    }
  `)

  shopify.allShopifyProduct.edges.forEach(({ node }) => {
    shopifyList = [...shopifyList, node]
  })
  return shopifyList
}
