import WORKOUT_PLANS_DATA from "./products/workout-plans"
import MEAL_PLANS_DATA from "./products/meal-plans"
import SUPPLEMENTS_DATA from "./products/supplements"
import BUNDLES_DATA from "./products/bundles"
import OFFERS_DATA from "./products/offers"

// SHOPIFY PRODUCTS CONTENT ---------------------------------------
const FULL_PRODUCT_DATA = {
  ...WORKOUT_PLANS_DATA,
  ...MEAL_PLANS_DATA,
  ...SUPPLEMENTS_DATA,
  ...BUNDLES_DATA,
  ...OFFERS_DATA,
}
export default FULL_PRODUCT_DATA
