import { useEffect, useState } from "react"

// Change the target date timezone and calculate the difference with the current timezone
export const changeTimezone = date => {
  const changedDate = new Date(
    date.toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    }),
  )

  const diff = changedDate.getTime() - date.getTime()
  return new Date(date.getTime() - diff)
}

export const useCountdown = targetDate => {
  // Set the target date in PST timezone
  const countDownDate = changeTimezone(new Date(targetDate))

  // Set the state of the countdown, the target time - the current time
  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime())

  // Activate the countdown
  useEffect(() => {
    let interval = null

    if (countDownDate - new Date().getTime() < 0) {
      clearInterval(interval)
      return
    }

    interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

// Calculate days, hours, minutes, seconds left
const getReturnValues = countDown => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  if (days + hours + minutes + seconds <= 0) {
    return [0, 0, 0, 0]
  } else {
    return [days, hours, minutes, seconds]
  }
}

// Check if the current day is in the [start date, end date] range
export const compareDates = (today, startDate, endDate) => {
  let currentTime = today.getTime()
  let startTime = changeTimezone(new Date(startDate)).getTime()
  let endTime = changeTimezone(new Date(endDate)).getTime()

  return currentTime >= startTime && currentTime <= endTime
}
