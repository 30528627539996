/**
 * Slide Up Functionality
 * @param target - the target that is animated
 * @param parent - the parent of the target
 * @param duration - how long the animation should last in ms (optional)
 * */
export function slideUp(target, parent, duration = 300) {
  if (target) {
    target.style.height = target.offsetHeight + "px"
    parent.style.pointerEvents = "none"

    window.setTimeout(() => {
      target.style.overflow = "hidden"
      target.style.boxSizing = "border-box"
      target.style.transitionProperty = "height, margin, padding"
      target.style.transitionDuration = duration + "ms"
      target.style.paddingTop = 0
      target.style.paddingBottom = 0
      target.style.marginTop = 0
      target.style.marginBottom = 0
      target.style.height = 0
    }, 100)
    window.setTimeout(() => {
      target.style.display = "none"
      target.style.removeProperty("height")
      target.style.removeProperty("padding-top")
      target.style.removeProperty("padding-bottom")
      target.style.removeProperty("margin-top")
      target.style.removeProperty("margin-bottom")
      target.style.removeProperty("transition-duration")
      target.style.removeProperty("transition-property")
      parent.style.removeProperty("pointer-events")
    }, duration + 100)
  }
}

/**
 * Slide Down Functionality
 * @param target - the target that is animated
 * @param parent - the parent of the target
 * @param duration - how long the animation should last in ms (optional)
 * */
export function slideDown(target, parent, duration = 300) {
  if (target) {
    target.style.removeProperty("display")
    parent.style.pointerevents = "none"
    let display = window.getComputedStyle(target).display

    if (display === "none") display = "block"

    target.style.display = display
    let height = target.offsetHeight
    target.style.height = 0
    target.style.paddingTop = 0
    target.style.paddingBottom = 0
    target.style.marginTop = 0
    target.style.marginBottom = 0

    window.setTimeout(() => {
      target.style.overflow = "hidden"
      target.style.boxSizing = "border-box"
      target.style.transitionProperty = "height, margin, padding"
      target.style.transitionDuration = duration + "ms"
      target.style.height = height + "px"
      target.style.removeProperty("padding-top")
      target.style.removeProperty("padding-bottom")
      target.style.removeProperty("margin-top")
      target.style.removeProperty("margin-bottom")
    }, 100)
    window.setTimeout(() => {
      target.style.removeProperty("height")
      target.style.removeProperty("transition-duration")
      target.style.removeProperty("transition-property")
      parent.style.removeProperty("pointer-events")
    }, duration + 100)
  }
}

/**
 * Slide Toggle Functionality
 * @param target - the target that is animated
 * @param parent - the parent of the target
 * @param duration - how long the animation should last in ms (optional)
 * */
export function slideToggle(target, parent, duration = 300) {
  if (target) {
    if (window.getComputedStyle(target).display === "none") {
      return slideDown(target, parent, duration)
    } else {
      return slideUp(target, parent, duration)
    }
  }
}
